import ImageZoomComponent from '@wix/thunderbolt-elements/src/components/MediaZoom/ImageZoom/viewer/ImageZoom';


const ImageZoom = {
  component: ImageZoomComponent
};


export const components = {
  ['ImageZoom']: ImageZoom
};

